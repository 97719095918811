<template>
  <div v-if="releaseNotes.length" class="v-dashboard__box v-dashboard__box--green" data-test-id="new-features-announcement-box">
    <div class="v-dashboard__box__text">
      <h3 class="v-dashboard__box__title v-dashboard__box__title--green " data-test-id="new-features-announcement-title">{{ $t('releaseNotesTitle') }}</h3>
      <ul>
        <li data-test-id="new-feature" v-for="releaseNote of releaseNotes">{{ releaseNote }}</li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    releaseNotes() {
      return this.enabledFeatures()
        .map(f => `${f}_announcement`)
        .filter(f => this.$te(f))
        .flatMap(f => this.$t(f))
    }
  },
  methods: {
    enabledFeatures() {
      return Object.entries(this.$store.state.features)
        .filter(([_, enabled]) => enabled)
        .map(([feature, _]) => feature)
    }
  }
}
</script>
