<template>
  <div class="page v-dashboard">
    <div class="page__header row justify-content-center">
      <div class="col-12 col-xs-11 page__header__content">
        <h1 class="page__header__title">{{ $t('dashboard') }}</h1>
      </div>
    </div>

    <div class="page__body page__body--white row justify-content-center">
      <div class="col-12 col-xs-11 page__body__col">
        <h2 class="v-dashboard__salutation">{{ $t('hello') }} {{ currentUser.firstName }}!</h2>
        <div class="row">
          <div class="col-sm-6 v-dashboard__col">
            <div class="v-dashboard__box">
              <round-crest v-if="status !== 'CLOSED'" :content="globalRating" crestClass="a-crest--primary" />
              <round-crest v-else :content="'-'" crestClass="a-crest--gray" />
              <div class="v-dashboard__box__text">
                <h3 class="v-dashboard__box__title">{{ $t('yourRating') }}</h3>
                <p>{{ $t('yourRatingText') }}</p>
              </div>
            </div>
          </div>
          <div class="col v-dashboard__col">
            <new-features-announcement />
            <custom-announcements />
          </div>
        </div>
      </div>
    </div>
    <div class="page__body row justify-content-center">
      <div class="col-12 col-xs-11 page__body__col">
        <h3 class="v-dashboard__title">{{ $t('lastOngoingProjects') }}</h3>
        <loading-wrapper :isLoading="ongoingProjectsLoading">
          <grid-gcr :data="ongoingProjects" :columns="ongoingProjectsColumns" v-model="ongoingProjectsSort"></grid-gcr>
          <div class="page__body__btns">
            <router-link :to="ongoingProjectsUrl" class="btn btn-primary btn-sm btn-arrow">{{ $t('seeAllProjects') }}</router-link>
          </div>
        </loading-wrapper>
      </div>
    </div>
  </div>
</template>

<script>
import { Consts, Urls } from '@/_helpers'
import { Projects } from '@/_helpers/projects'
import RoundCrest from '@/components/atoms/RoundCrest'
import GridGcr from '@/components/molecules/Grid'
import LoadingWrapper from '@/components/organism/LoadingWrapper'
import ButtonGcr from '@/components/atoms/Button'
import getProjectsByStatus from '@/graphql/accreditations/projects/getProjectsByStatus.graphql'
import currentUserFirstName from '@/graphql/users/getCurrentUserFirstName.graphql'
import userByEmailFirstName from '@/graphql/users/getUserByEmailFirstName.graphql'
import getAccreditationSummary from '@/graphql/accreditations/getAccreditationSummary.graphql'
import { mapGetters } from 'vuex'
import NewFeaturesAnnouncement from '@/views/NewFeaturesAnnouncement'
import CustomAnnouncements from '@/views/CustomAnnouncements'


require('../utilities/page')
require('../utilities/button')

export default {
  components: { RoundCrest, GridGcr, LoadingWrapper, ButtonGcr, NewFeaturesAnnouncement, CustomAnnouncements },
  data() {
    return {
      currentUser: {},
      status: '',
      globalRating: '',
      ongoingProjects: [],
      ongoingProjectsSort: {
        direction: 'DESC',
        key: 'projectRegisterDate'
      },
      ongoingProjectsUrl: {
        name: Urls.PROJECTS,
        query: { ct: Consts.ONGOING_PROJECTS }
      }
    }
  },
  computed: {
    ...mapGetters({
      isSAC: Consts.GETTER_USER_ISSAC,
      currentAccreditation: Consts.GETTER_ACCREDITATION_CURRENT_ID
    }),
    ongoingProjectsColumns() {
      return [
        { sortable: false, name: 'projectRegisterDate' },
        { sortable: false, name: 'projectRegisterNumber' },
        { sortable: false, name: 'projectNameColumn' },
        { sortable: false, name: 'projectAddress' },
        { sortable: false, name: 'projectDescription' },
        { sortable: false, name: 'projectStatus' },
        { sortable: false, name: 'projectActions' }
      ]
    },
    ongoingProjectsLoading() {
      return this.$apollo.queries.getOngoingProjects.loading
    }
  },
  apollo: {
    getcurrentuser: {
      query: currentUserFirstName,
      skip() {
        return this.isSAC
      },
      update(data) {
        this.currentUser = data.currentUser
      }
    },
    getuserByEmail: {
      query: userByEmailFirstName,
      variables() {
        return {
          email: this.$store.getters[Consts.GETTER_USER_EMAILSAC]
        }
      },
      skip() {
        return !this.isSAC
      },
      update(data) {
        this.currentUser = data.userByEmail
      }
    },
    getAccreditation: {
      query: getAccreditationSummary,
      variables() {
        return {
          id: this.currentAccreditation
        }
      },
      update(data) {
        this.globalRating = data.accreditation.globalRating
        this.status = data.accreditation.status
      }
    },
    getOngoingProjects: {
      query: getProjectsByStatus,
      variables() {
        return {
          id: this.currentAccreditation,
          search: {
            workflowStep: Consts.ONGOING_PROJECTS,
            sortOption: 'REGISTRATION_DATE',
            sortDirection: 'DESC'
          },
          limit: 10
        }
      },
      update(data) {
        this.ongoingProjects = Projects.toGrid(data.accreditation.projects.items, data.accreditation.id)
      }
    }
  }
}
</script>

<style lang="scss">
@import '../utilities/config';

.page__header__title {
  &::after {
    background-image: url(../assets/dashboard_gray.svg);
  }
}

.v-dashboard {
  &__salutation {
    margin-bottom: 25px;
  }

  &__col {
    padding-bottom: 30px;
  }

  &__box {
    border-radius: 7px;
    border: 1px solid gray('gray-150');
    padding: 35px 10px;
    height: 100%;
    font-size: 14px;
    display: flex;
    align-items: center;

    &--gray {
      color: gray('white');
      background-color: gray('gray-400');
    }

    &--green {
      border-color: theme-color('primary');
    }

    &__text {
      padding: 0 10px;
      opacity: 1;

      a {
        &,
        &:hover,
        &:focus,
        &:active {
          color: inherit;
        }
      }

      & > :first-child {
        margin-top: 0;
      }

      & > :last-child {
        margin-bottom: 0;
      }
    }

    &__title {
      font-size: 17px;

      &--green {
        color: theme-color('primary');

        &:before {
          content: '';
          background-color: theme-color('primary');
          position: absolute;
          left: 15px;
          top: 28px;
          width: 5px;
          height: 40px;
        }
      }
    }

    &__notice {
      font-size: 11px;
      font-style: italic;
    }
  }

  &__title {
    font-size: 23px;
    font-weight: 500; //font-weight: 400;;
  }

  &__subtitle {
    font-size: 17px;
    color: gray('gray-400');
    font-weight: 500; //font-weight: 400;;
  }
}
</style>
