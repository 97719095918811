import i18n from '@/i18n'
import { Consts } from './consts'
import { Urls } from '@/_helpers/urls'
import { Dates } from '@/_helpers/dates'
import { router } from '@/_helpers/router'
import { buildingDescription } from '@/_helpers/buildings'
import { address } from '@/_helpers/address'

export const Projects = {
  toGrid
}

function toGrid(projects, accreditationId) {
  return projects.map(project => toRow(project, accreditationId))
}

function toRow(project, accreditationId) {
  return {
    id: project.id,
    onclick: `${Urls.localizedUrl(Urls.PROJECT_DETAILS)}?id=${project.id}`,
    projectNameColumn: project.name,
    projectCreationDate: Dates.formatDate(project.creationDate),
    projectRegisterDate: Dates.formatDate(project.registrationDate) || '--',
    projectRegisterNumber: project.registrationNumber || '--',
    projectAddress: addressOf(project),
    projectLotNumber: project.lotNumber || firstBuilding(project).lotNumber || '--',
    projectDescription: projectDescription(project),
    projectStatus: { type: 'list', list: getProjectStatus(project.status) },
    projectActions: { type: 'list', list: getProjectActions(project) },
    projectButtons: { type: 'list', list: getProjectButtons(project, accreditationId) }
  }
}

function addressOf(project) {
  let projectAddress = {
    apartment: project.buildings[0]?.apartment || project.apartment,
    buildingNumber: project.buildings[0]?.buildingNumber || project.streetNumber,
    street: project.buildings[0]?.street || project.streetName,
    city: project.city
  }

  return `<strong>${address(projectAddress)}</strong>`
}

const emptyProjectTypeDescriptions = {
  SOLE_OWNERSHIP: i18n.t('emptyProjectDescSoleOwnership'),
  VERTICAL_CO_OWNERSHIP: i18n.t('emptyProjectDescVerticalCoOwnership'),
  HORIZONTAL_CO_OWNERSHIP: i18n.t('emptyProjectDescHorizontalCoOwnership')
}

function projectDescription(project) {
  if (!project.buildings.length) return emptyProjectTypeDescriptions[project.type]

  if (project.type === 'HORIZONTAL_CO_OWNERSHIP')
    return i18n.t('projectDescMultipleBuilding', { buildingCount: project.buildings.length })

  return buildingDescription(project.buildings[0]) || emptyProjectTypeDescriptions[project.type]
}

function getProjectActions(prj) {
  var labels = []

  switch (prj.status) {
    case Consts.NEW_PROJECTS: {
      labels.push({
        badge: 'badge-gray',
        badgeText: i18n.t('projectActionComplete')
      })
      break
    }
    case Consts.REGISTERED_PROJECTS:
    case Consts.SUBMITTED_PROJECTS: {
      if (prj.hasAmountDue) {
        labels.push({
          badge: 'badge-warning',
          badgeText: i18n.t('projectActionPay')
        })
      }
      if (prj.missingDocumentsCount > 0) {
        labels.push({
          badge: 'badge-warning',
          badgeText: i18n.t('projectActionDoc')
        })
      }
    }
  }
  return labels
}

function getProjectButtons(prj, accreditationId) {
  const buttons = []
  if (prj.status === Consts.NEW_PROJECTS) {
    buttons.push({
      button: 'project-info__edit btn-edit btn-edit-sm',
      buttonTooltip: i18n.t('edit'),
      onclick: () => router.push({
        name: Urls.EDIT_NEW_PROJECT,
        query: { id: prj.id }
      })
    })

    buttons.push({
      buttonProject: 'delete',
      projectId: prj.id,
      accreditationId
    })
  }

  return buttons
}

function getProjectStatus(status) {
  var labels = []

  switch (status) {
    case Consts.NEW_PROJECTS: {
      labels.push({
        badge: 'badge-gray',
        badgeText: i18n.t('projectStatusNew')
      })
      break
    }
    case Consts.REGISTERED_PROJECTS: {
      labels.push({
        badge: 'badge-gray',
        badgeText: i18n.t('projectStatusRegistered')
      })
      break
    }
    case Consts.REGISTERED_AS_RENTAL: {
      labels.push({
        badge: 'badge-gray',
        badgeText: i18n.t('projectStatusRegisteredAsRental')
      })
      break
    }
    case Consts.SUBMITTED_PROJECTS: {
      labels.push({
        badge: 'badge-gray',
        badgeText: i18n.t('projectStatusSubmitted')
      })
      break
    }
    case Consts.CLOSED_PROJECTS: {
      labels.push({
        badge: 'badge-primary',
        badgeText: i18n.t('projectStatusClosed')
      })
      break
    }
  }

  return labels
}

function firstBuilding(project) {
  return project.buildings && project.buildings.length ? project.buildings[0] : {}
}
