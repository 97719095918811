<template>
  <div v-if="showAnnouncement" class="v-dashboard__box v-dashboard__box--green" data-test-id="custom-announcements-box">
    <div class="v-dashboard__box__text">
      <h3 class="v-dashboard__box__title v-dashboard__box__title--green " data-test-id="custom-announcements-title">{{ $t('releaseNotesTitle') }}</h3>
      <span data-test-id="custom-announcements" v-html="$t('releaseNotesCustomHtml')"/>
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    showAnnouncement() {
      return this.$store.state.features.multibuilding
    }
  }
}
</script>
